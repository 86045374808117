import React　from 'react'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"

// スタイルの記述をする
const useStyles = makeStyles(() => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
  content: {
    marginTop: 30, 
    display: 'grid'
  },
  form: {
    marginTop: 50,
    marginBottom: 100,
  },
  error: {
    color: "#f44336"
  },
  checkGroup: {
    marginLeft: 20,
    marginRight: 2
  }
  
}));

const Error = ({arg, className}) => {
  return (arg!==undefined && <p className={className} key={arg.type}>{arg.message}</p>)
}

const Form = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async data => {
    console.log(data);
    // Slack通知
    await fetch('https://hooks.slack.com/services/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', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({
        text: 
          `carrot問い合わせ内容
          会社: ${data.company} 
          名前: ${data.name}
          メールアドレス: ${data.email}
          問い合わせ内容: ${data.message}`
        })
    })
    .then(response => console.log)
    .catch(error => console.error);

 
    //e.preventDefault()
    let myForm = document.getElementById('contact_form');
    let formData = new FormData(myForm)
    await fetch(`${process.env.GATSBY_BACKEND_URL}/contacts`, {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    }).then(() => console.log('Form successfully submitted')).catch((error) => alert(error))


    window.location.href='/thanks';
  };

  const classes = useStyles();

  return (
    <Container>

      <form id= "contact_form" name="contact" 
        data-netlify="true"
        data-netlify-honeypot="bot-field" 
        method="POST" 
        className={classes.form} onSubmit={handleSubmit(onSubmit) }>
      
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
        
          <div className={classes.content}>
            <TextField id="outlined-company" label="会社名 *" variant="outlined" placeholder="スタンシステム株式会社" error={Boolean(errors.company,)} {...register("company", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"}}) } />
            <Error className={classes.error} arg={errors.company} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-department" label="部署 *" variant="outlined"  placeholder="システム部" error={Boolean(errors.department)} {...register("department", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })} />
            <Error className={classes.error} arg={errors.department} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-name" label="お名前 *" variant="outlined"  placeholder="徳島　太郎" error={Boolean(errors.name)} {...register("name", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })}/>
            <Error className={classes.error} arg={errors.name} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-email" label="メールアドレス *" variant="outlined"  placeholder="〇〇〇〇＠example.com" error={Boolean(errors.email)} {...register("email", { required: "必須入力です", pattern: {value: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i, message: "不正な入力です"}, maxLength: {value: 100, message: "最大文字数は100文字です"}  })} />
            <Error className={classes.error} arg={errors.email} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-message" label="お問い合わせ内容 *" variant="outlined"  placeholder="" error={Boolean(errors.message)} {...register("message", { required: "必須入力です", maxLength: {value: 1000, message: "最大文字数は1000文字です"}}) }   multiline rows={4} rowsMax={8} />
            <Error className={classes.error} arg={errors.message} />
          </div>
					<div className={classes.content} >
						
						<Card style={{height: "200px", overflowY: "auto"}}>
      				<CardContent>
								<h4>プライバシーポリシー<br /></h4>
							<div style={{marginTop: 20}}>
								ご入力頂いた個人情報は、下記の目的で利用いたします。<br />
								その他の取扱いについては
                <a href="https://www.stansystem.co.jp/info/privacy_policy"　target="_blank" rel="noreferrer">
                  弊社プライバシーポリシー
                </a>に従います。
							</div>
							<div style={{margin: 25}}>
								<ul>
									<li>お問い合わせへの回答のため</li>
									<li>ご本人確認と個別のご連絡のため</li>
									<li>広告宣伝(電子メールによるものを含む)、アンケート、市場調査のため</li>
									<li>サービス品質改善に向けた利用分析及び統計データ作成のため</li>
								</ul>
							</div>
							<div>上記の内容でよろしければ送信ボタンを押してください。送信ボタンを押すと同意したものとさせていただきます。</div>
								<CardActions>
                  <a href="https://www.stansystem.co.jp/info/privacy_policy"　target="_blank" rel="noreferrer">
                    弊社プライバシーポリシー
                  </a>
								</CardActions>
							</CardContent>
						</Card>
					</div>
          <div className={classes.content}><Button style={{fontSize: "18px"}} variant="contained" color="primary" type="submit">送信</Button></div>
        
      </form>
    </Container>
  );
}

const ContactPage = (props) => {
  const breakpoints = useBreakpoint();
  const classes = useStyles();
  return (
    <Layout location={props.location} breakpoints={breakpoints}>
    <Seo title="お問合せ" />
      <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="carrot_eyecatch.jpeg" style={{height: "inherit"}}/></div>
      <div className={classes.phrase} style = {{color: "white",fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
        お問合せ
      </div>
      <div style={{backgroundColor: "white", paddingTop: 50}}>
        {breakpoints && <Form breakpoints = {breakpoints}/>}
      </div>
    </Layout>
  )
}

export default ContactPage